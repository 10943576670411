import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <p>{`We use a relatively extensive set of cores within CSS. `}<br />{`
All colors in addition to being SASS variables (like `}<inlineCode parentName="p">{`$pink-50`}</inlineCode>{`) are also CSS variables to use globally like `}<inlineCode parentName="p">{`var(--pink-50)`}</inlineCode>{`.`}</p>
    <p>To see all the colors and their variations go to the <a className='pink' href='/styleguide'>Styleguide page</a></p>
    <p className='mb-3'>All colors have tone variations from 5 to 95, jumping every 5 like var(--pink-5), var(--pink-10), var(--pink-15)...</p>
    <h2 {...{
      "id": "color-attribute"
    }}>{`Color Attribute`}</h2>
    <p>{`To change the `}<inlineCode parentName="p">{`color`}</inlineCode>{` rule just use the color name as class.`}</p>
    <p className='pink-50'>
  I`m a pink paragraph like 'var(--pink-50)'
    </p>
    <p className='pink-30'>
  I`m a lighter pink paragraph like 'var(--pink-30)'
    </p>
    <p className='aqua-50'>
  I`m a aqua paragraph like 'var(--aqua-30)'
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='pink-50'>
  I\`m a pink paragraph like 'var(--pink-50)'
</p>
<p class='pink-30'>
  I\`m a lighter pink paragraph like 'var(--pink-30)'
</p>
<p class='aqua-50'>
  I\`m a aqua paragraph like 'var(--aqua-30)'
</p>
`}</code></pre>
    <h2 {...{
      "id": "background-color-attribute"
    }}>{`Background Color Attribute`}</h2>
    <p>{`To change the `}<inlineCode parentName="p">{`background-color`}</inlineCode>{` rule just use the color name as class with the prefix `}<inlineCode parentName="p">{`background-`}</inlineCode>{`.`}</p>
    <p className='background-pink-50 white-html p-2'>
  I`m a paragraph with background pink like 'var(--pink-50)'
    </p>
    <p className='background-pink-30 white-html p-2'>
  I`m a paragraph with lighter background pink like 'var(--pink-30)'
    </p>
    <p className='background-aqua-50 white-html p-2'>
  I`m a paragraph with background aqua like 'var(--aqua-30)'
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='background-pink-50'>
  I\`m a paragraph with background pink like 'var(--pink-50)'
</p>
<p class='background-pink-30'>
  I\`m a paragraph with lighter background pink like 'var(--pink-30)'
</p>
<p class='background-aqua-50'>
  I\`m a paragraph with background aqua like 'var(--aqua-30)'
</p>
`}</code></pre>
    <h2 {...{
      "id": "border-color-attribute"
    }}>{`Border Color Attribute`}</h2>
    <p>{`To change the `}<inlineCode parentName="p">{`border-color`}</inlineCode>{` rule just use the color name as class with the prefix `}<inlineCode parentName="p">{`border-`}</inlineCode>{`.
The element needs to have the `}<inlineCode parentName="p">{`border`}</inlineCode>{` property set, the example below is using `}<inlineCode parentName="p">{`border: 1px solid transparent`}</inlineCode>{`.
The `}<inlineCode parentName="p">{`transparent`}</inlineCode>{` will be overwritten by the color class.`}</p>
    <p className='border-pink-50 p-2' style={{
      "border": "1px solid transparent"
    }}>
  I`m a paragraph with border pink like 'var(--pink-50)'
    </p>
    <p className='border-pink-30 p-2' style={{
      "border": "1px solid transparent"
    }}>
  I`m a paragraph with lighter border pink like 'var(--pink-30)'
    </p>
    <p className='border-aqua-50 p-2' style={{
      "border": "1px solid transparent"
    }}>
  I`m a paragraph with border aqua like 'var(--aqua-30)'
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='border-pink-50' style='border: 1px solid transparent'>
  I\`m a paragraph with border pink like 'var(--pink-50)'
</p>
<p class='border-pink-30' style='border: 1px solid transparent'>
  I\`m a paragraph with lighter border pink like 'var(--pink-30)'
</p>
<p class='border-aqua-50' style='border: 1px solid transparent'>
  I\`m a paragraph with border aqua like 'var(--aqua-30)'
</p>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      